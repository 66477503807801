import React from 'react'
import { Button, Row, Col } from 'antd'
import { Network } from 'vis-network'
import { options, prepareNodes, prepareEdges } from './mapDraw'
import StyledMapElementForm from './mapElementForm'
import { messageTypes } from '../../../../actions/messages'
import { openNotificationPopup } from '../../../../actions/helpers'
import settings from '../../../../config/'

class StyledMapEditor extends React.Component {
  state = {
    edgesCounter: 1,
    nodesCounter: 1,
    mappings: {},
    draggingNode: undefined,
    saveBlock: false
  }

  networkRef = React.createRef()
  network = null

  fitMap = () => {
    const options = {
      animation: {
        duration: 1000,
        easingFunction: 'easeInOutQuad'
      }
    }
    this.network.fit(options)
  }

  isClickableNode = (clickedNode) => {
    if (typeof clickedNode === 'string' && clickedNode.slice(-7) === '_config') {
      const id = clickedNode.split('_')[0]
      const node = this.props.map.nodesData.find(x => x.id === id)
      return { type: 'node', entity: node }
    }

    const node = this.props.map.nodesData.find(x => x.id.toString() === clickedNode.toString())

    if (node) {
      if (node.group === 'network') {
        return false
      }

      return { type: 'node', entity: node }
    }

    const edge = this.props.map.edgesData.find(x => x.id === clickedNode)

    if (edge) {
      return { type: 'edge', entity: edge }
    }
  }

  setSaveBlock = (saveBlock) => {
    this.setState({ saveBlock })
  }

  getNextNodesCounter = () => {
    const nodesCounter = this.state.nodesCounter + 1
    this.setState({ nodesCounter })

    return nodesCounter
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // show notify
    openNotificationPopup(messageTypes[this.props.language].copied_to_clipboard, str, 'smile')
  }

  isForbiddenNode = (clickedNode) => {
    const splited = clickedNode.split('_')

    if (splited.length > 1) {
      splited.shift()
      const clean = splited.join('_')

      if (['subtitle', 'subtitle2', 'border_top', 'border_bottom', 'border_left', 'border_right', 'label', 'sublabel', 'ip', 'ip2', 'dns', 'ip_public', 'dns_public', 'attribute', 'left', 'right', 'attribute', 'top', 'bottom'].includes(clean)) {
        return true
      }
    }

    return false
  }

  checkCanUnselectElement = () => {
    const { data, language } = this.props

    if (this.state.saveBlock) {
      // if save block is set select current element again on map
      if (data !== null) {
        if (data.type === 'node') {
          this.network.selectNodes([data.entity.id])
        } else if (data.type === 'edge') {
          this.network.selectEdges([data.entity.id])
        }
      }

      openNotificationPopup(messageTypes[language].oops, messageTypes[language].map_cannot_unselect_element, 'frown')

      return false
    }
    return true
  }

  buildNetwork () {
    const { language, map, data } = this.props
    const nodesData = map.nodesData
    const edgesData = map.edgesData

    const nodesPrepared = prepareNodes(nodesData, this.isClickableNode, this.props.language, true)
    const nodes = nodesPrepared.nodes
    const mappings = nodesPrepared.mappings
    const edges = prepareEdges(nodesData, edgesData)
    const maxEdge = (map.edgesData.length && Math.max(...map.edgesData.map(o => o.id))) || 1
    const maxNode = (map.nodesData.length && Math.max(...map.nodesData.map(o => parseInt(o.id.toString().replace('unknown', '')) || 0))) || 1

    this.setState({ mappings, nodesCounter: maxNode + 1, edgesCounter: maxEdge + 1 })

    const mapData = {
      nodes,
      edges
    }

    const locales = {}
    locales[language] = {
      back: messageTypes[language].cancel,
      addNode: messageTypes[language].map_add_node,
      addEdge: messageTypes[language].map_add_edge,
      addDescription: messageTypes[language].map_add_description,
      edgeDescription: messageTypes[language].map_add_edge_description
    }

    // map options
    const networkOptions = {
      ...options,
      locales,
      locale: language,
      interaction: {
        hover: true,
        dragNodes: true,
        navigationButtons: true,
        selectConnectedEdges: false,
        multiselect: true
      },
      manipulation: {
        initiallyActive: true,
        enabled: true,
        deleteNode: false,
        deleteEdge: false,
        editEdge: false,
        addNode: function (data, callback) {
          const mapX = _this.props.map
          const nextNodeNr = _this.getNextNodesCounter()
          const newNode = { id: 'unknown' + nextNodeNr, _id: 'unknown' + nextNodeNr, type: 'vm', group: 'server_linux', label: 'nowy', x: Math.round(data.x), y: Math.round(data.y) }

          // do not allow add new node if element with same id exists
          if (!mapX.nodesData.find(x => x.id === newNode.id)) {
            mapX.nodesData.push(newNode)
            _this.props.updateMapData(mapX)
            _this.buildNetwork()
          } else {
            console.error('Cannot add node with the same id: NEW_NODE => ' + JSON.stringify(newNode))
          }
        },
        addEdge: function (data, callback) {
          if (data.from === data.to) return false

          const edgeId = _this.state.edgesCounter
          _this.setState({ edgesCounter: edgeId + 1 })

          const mapX = _this.props.map
          const newEdge = { id: edgeId, from: data.from, to: data.to }

          // do not allow add new edge if element with same id exists
          if (!mapX.edgesData.find(x => x.id === newEdge.id)) {
            mapX.edgesData.push(newEdge)
            _this.props.updateMapData(mapX)
            _this.buildNetwork()
          } else {
            console.error('Cannot add edge with the same id: NEW_EDGE => ' + JSON.stringify(newEdge))
          }
        }
      }
    }

    const _this = this

    // map initialization
    this.network = new Network(this.networkRef.current, mapData, networkOptions)

    // if element properties are opened, select corresponding node on map
    if (data !== null && !data.entity._deleted) {
      if (data.type === 'node') {
        if (data.entity.type === 'network') {
          this.network.selectNodes([data.entity.id + '_config'])
        } else {
          this.network.selectNodes([data.entity.id])
        }
      } else if (data.type === 'edge') {
        this.network.selectEdges([data.entity.id])
      }
    }

    // on right mouse click, fit map to window
    this.network.on('oncontext', function (params) {
      params.event.preventDefault()
      _this.fitMap()
    })

    this.network.on('dragStart', function (params) {
      if (params.nodes.length > 0) {
        const clickedNode = params.nodes[0]
        let nodeId = clickedNode

        if (typeof clickedNode === 'string' && _this.isForbiddenNode(clickedNode)) {
          _this.network.unselectAll()
          return false
        }

        if (typeof clickedNode === 'string' && clickedNode.slice(-7) === '_config') {
          const id = clickedNode.split('_')[0]
          nodeId = id
        }

        _this.setState({ draggingNode: nodeId })

        if (_this.state.mappings[nodeId] !== undefined) {
          _this.network.selectNodes(_this.state.mappings[nodeId].concat([nodeId]))
        }
      }
    })

    this.network.on('dragEnd', function (params) {
      const nodeId = _this.state.draggingNode

      if (nodeId) {
        _this.setState({ draggingNode: undefined })
        const mapX = _this.props.map
        const n = mapX.nodesData.findIndex(x => x.id === nodeId)

        if (mapX.nodesData[n]?.group === 'network') {
          const pos = _this.network.getPosition(nodeId + '_config')

          mapX.nodesData[n].x = Math.round(pos.x + (mapX.nodesData[n].width / 2) - 20)
          mapX.nodesData[n].y = Math.round(pos.y + (mapX.nodesData[n].height / 2) + 25)
        } else {
          const pos = _this.network.getPosition(nodeId)

          mapX.nodesData[n].x = Math.round(pos.x)
          mapX.nodesData[n].y = Math.round(pos.y)
        }

        _this.props.updateMapData(mapX)

        if (_this.props.data !== null && mapX.nodesData[n].id === _this.props.data.entity.id) {
          _this.props.setData({ type: 'node', entity: mapX.nodesData[n] })
          _this.props.incUpdated()
        }

        _this.buildNetwork()
      }
    })

    this.network.on('deselectNode', function (params) {
      if (params.edges.length <= 0 && params.nodes.length <= 0) {
        if (_this.state.saveBlock) {
          // if save block is set do not allow unselect node
          _this.network.selectNodes([_this.props.data?.entity.id])
          openNotificationPopup(messageTypes[_this.props.language].oops, messageTypes[_this.props.language].map_cannot_unselect_element, 'frown')

          return false
        } else {
          _this.props.setData(null)
        }
      }
    })

    this.network.on('deselectEdge', function (params) {
      if (params.edges.length <= 0 && params.nodes.length <= 0) {
        if (_this.state.saveBlock) {
          // if save block is set do not allow unselect node
          _this.network.selectEdges([_this.props.data?.entity.id])
          openNotificationPopup(messageTypes[_this.props.language].oops, messageTypes[_this.props.language].map_cannot_unselect_element, 'frown')

          return false
        } else {
          _this.props.setData(null)
        }
      }
    })

    this.network.on('selectNode', function (params) {
      const { data, setData } = _this.props

      if (!_this.checkCanUnselectElement()) {
        return false
      }

      if (params.nodes.length === 1 && params.edges.length === 0) {
        const clickedNode = params.nodes[0]

        // check for forbidden ids parts
        if (_this.isForbiddenNode(clickedNode)) {
          setData(null)
          _this.network.unselectAll()
          return false
        }

        const clickedNodeObject = nodes.get(clickedNode)
        const clickedElement = _this.isClickableNode(clickedNode)

        // check for forbidden groups
        if (clickedNodeObject.group === 'network_edge_point_config') {
          setData(null)
          _this.network.unselectAll()
          return false
        }

        if (clickedNodeObject && clickedNodeObject.toCopy !== undefined) {
          _this.copyToClipboard(clickedNodeObject.toCopy)
        } else if (clickedElement) {
          setData(clickedElement)

          if (data !== null) {
            _this.props.incUpdated()
          }
        }
      } else {
        setData(null)
      }
    })

    this.network.on('selectEdge', function (params) {
      const { setData } = _this.props

      if (!_this.checkCanUnselectElement()) {
        return false
      }

      if (params.nodes.length === 0 && params.edges.length === 1) {
        const clickedNode = params.edges[0]
        const clickedElement = _this.isClickableNode(clickedNode)

        if (clickedElement) {
          setData(clickedElement)
        }
      } else {
        setData(null)
      }
    })

    // canvas handle is needed to allow cursor changing
    const networkCanvas = this.networkRef.current.getElementsByTagName('canvas')[0]

    // simple function to change cursor over node
    function changeCursor (newCursorStyle) {
      networkCanvas.style.cursor = newCursorStyle
    }

    // hover node event
    this.network.on('hoverNode', function (params) {
      if (params.node) {
        const clickedNode = params.node

        if (_this.isClickableNode(clickedNode)) {
          changeCursor('pointer')
        }
      }
    })

    this.network.on('hoverEdge', function (params) {
      if (params.edge) {
        const clickedNode = params.edge

        if (_this.isClickableNode(clickedNode)) {
          changeCursor('pointer')
        }
      }
    })

    // blur node event
    this.network.on('blurNode', function (params) {
      changeCursor('default')
    })
    this.network.on('blurEdge', function (params) {
      changeCursor('default')
    })
  }

  handleDeleteElement = () => {
    if (this.props.data.type === 'node') {
      const mapX = this.props.map
      const n = mapX.nodesData.findIndex(x => x.id === this.props.data.entity.id)

      mapX.nodesData[n]._deleted = true

      this.props.updateMapData(mapX)
      this.props.setData(null)

      const _this = this

      new Promise(function (resolve, reject) {
        _this.setState({ mappings: {} }, () => {
          resolve()
        })
      }).then(() => {
        _this.buildNetwork()
      })
    } else if (this.props.data.type === 'edge') {
      const mapX = this.props.map
      const n = mapX.edgesData.findIndex(x => x.id === this.props.data.entity.id)

      mapX.edgesData[n]._deleted = true

      this.props.updateMapData(mapX)
      this.props.setData(null)

      this.buildNetwork()
    }
  }

  componentDidMount () {
    // for editor set shadow for edges
    options.edges.chosen = {
      edge: function (values) {
        values.shadow = true
        values.shadowColor = 'red'
        values.shadowSize = 10
        values.shadowX = 0
        values.shadowY = 0
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { map, data, updated, loading } = this.props

    if ((map !== prevProps.map || (data === null && prevProps.data !== null) || updated !== prevProps.updated) && !loading) {
      this.buildNetwork()
    }
  }

  // updateNodePosition = (e) => {
  //   if (e.keyCode === 37) {
  //     console.log('key down --x')
  //     const selectedNode = this.props.data?.entity
  //
  //     selectedNode.x = selectedNode.x - 1
  //
  //     this.props.updateElement(selectedNode._id, selectedNode)
  //   }
  // }

  render () {
    const { language } = this.props

    return (
      <Row gutter={[0, 5]}>
        <Col span={16} className='map-editor'>
          <Button onClick={this.props.handleUpdateMapElements} disabled={this.state.saveBlock || this.props.loading}>{messageTypes[language].save}</Button>
          <div
            className='map-container'
            style={settings.app_map_bg
              ? {
                  backgroundImage: 'url(/images/customization/' + settings.app_map_bg + ')'
                }
              : {}}
          >
            <div x={this.props.updated} ref={this.networkRef} className='map fade-in' />
          </div>
        </Col>
        <Col span={8} className='map-properties-editor'>
          {(this.props.data &&
            <>
              <StyledMapElementForm
                data={this.props.data}
                map={this.props.map}
                flags={this.props.flags}
                challenges={this.props.challenges}
                updated={this.props.updated}
                language={language}
                updateElement={this.props.updateElement}
                setSaveBlock={this.setSaveBlock}
              />
              <Button className='map-delete-element-btn' onClick={this.handleDeleteElement}>{messageTypes[language].delete_element}</Button>
            </>) || messageTypes[language].map_select_element_to_edit}
        </Col>
      </Row>
    )
  }
}

export default StyledMapEditor
