import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd'
import { messageTypes } from '../../../actions/messages'
import MachineCard from '../../../components/sections/machines/machinesCard'
import ConsoleEmulator from '../../../components/sections/machines/consoleEmulator'
import { setConsoleOpened } from '../../../store/actions'

class StyledMapObjectModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: undefined,
      consoleOpen: false,
      machineId: null
    }
  }

  setStatus = (status) => {
    this.setState({
      status
    })
  }

  handleConsole = (machineId, consoleOpen) => {
    this.props.setConsoleOpened(consoleOpen)
    this.setState({ machineId })
  }

  render () {
    const { element, machineElement, language, visible, handleClose, machineCardTitle, stack, consoleOpened } = this.props
    // prepare element description
    const elementDescRaw = element.desc !== undefined && element.desc[language] !== undefined ? element.desc[language] : ''
    const elementDesc = elementDescRaw.split('\n')
    const { status } = this.state
    const allowClose = machineElement ? !((status === 'turning_off' || status === 'turning_on' || status === 'reverting' || status === 'rebooting')) : true

    return (
      <Modal
        title={machineElement ? undefined : element && element.name && element.name[language]}
        zIndex={1000}
        bodyStyle={machineElement ? { padding: 0 } : null}
        visible={visible}
        closable={false}
        className={'map-modal' + (element && element.disabled === true && stack && stack.demo === 'partial' ? ' machine-unavailable-demo' : '')}
        maskClosable={allowClose}
        footer={[
          this.props.getFileDownloadElement(element),
          <Button key='return' disabled={!allowClose} type='danger' onClick={() => handleClose()}>{messageTypes[language].close}</Button>
        ]}
      >
        {machineElement
          ? (
            <>
              {consoleOpened
                ? (
                  <ConsoleEmulator
                    language={language}
                    machine={machineElement}
                    machineId={machineElement.id}
                    consoleOpen={consoleOpened}
                    onHandleConsole={this.handleConsole}
                    insideMap
                  />
                  )
                : ''}
              <MachineCard
                title={machineCardTitle}
                element={machineElement}
                status={machineElement.resources?.status} // this is required to properly detect status change
                desc={elementDescRaw}
                getAllMachines={this.props.getAllMachines}
                setAllowStateStatus={this.props.setAllowStateStatus}
                allowStateStatus={this.props.allowStateStatus}
                clearAllowMachineStateStatus={this.props.clearAllowMachineStateStatus}
                onHandleConsole={this.handleConsole}
                setStatus={this.setStatus}
                stack={stack}
                insideMap
              />
            </>
            )
          : (
            <div>
              {elementDesc.map((val, i) => (
                <div key={i}>{val} <br /></div>
              ))}
            </div>
            )}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  consoleOpened: state.hdStore.consoleOpened
})

const mapDispatchToProps = {
  setConsoleOpened
}

const StyledMapObjectModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledMapObjectModal)

export default StyledMapObjectModalContainer
