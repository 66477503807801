import React, { Fragment } from 'react'
import { numberToArray, getCookie, confirm } from '../../actions/helpers'
import { messageTypes } from '../../actions/messages'
import config from '../../config'
import {
  Form, Select, Input, Button, Upload, Divider
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const { Option } = Select

class ResourceFilesFormComponent extends React.Component {
  fileTypes = ['pdf', 'txt', 'doc', 'docx', 'zip', 'jpg', 'png', 'xls', 'xlsx']

  formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  state = {
    filesArr: [],
    files: {},
    filesIds: {},
    filesTypes: {}
  }

  // change number of input files
  changeInputsNumber = (e, key) => {
    const num = parseInt(e.target.value)
    if (num) {
      const obj = {}
      obj[key] = numberToArray(num)
      this.setState(obj)
    }
  }

  // on load set form default values
  componentDidMount () {
    this.setDefaultValues()
  }

  // update form data when record changed or new record will be added
  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  // prepare form data for existing record
  prepareFormExistingValues (data) {
    return {}
  }

  // prepare values when on form submit
  prepareFormValues () {
    return {}
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = this.prepareFormValues(values)

    obj.files = []
    for (const i of this.state.filesArr) {
      const filesObj = {
        _id: i._id
      }
      filesObj.label = {}

      // prepare label fields for any language
      // eslint-disable-next-line
      config.languages.forEach((lang, x) => {
        filesObj.label[lang] = values['file_label_' + lang + '_' + i._id]
      })

      filesObj.file_type = values['file_type_' + i._id]

      obj.files.push(filesObj)
    }
    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  handleFileTypeChange = (fileType, i) => {
    const filesTypes = this.state.filesTypes

    filesTypes[i] = fileType

    this.setState({ filesTypes })
  }

  setDefaultValues = () => {
    const { data, resources } = this.props

    if (data && Object.keys(data).length > 0) {
      const fieldsVal = this.prepareFormExistingValues(data)

      const filesIds = {}
      const filesTypes = {}
      const filesArr = []

      if (resources) {
        for (let i = 0; i < resources.length; i++) {
          const fileId = resources[i]._id
          const filex = {
            _id: fileId
          }

          // prepare form values for field label and any language
          config.languages.forEach((lang, x) => {
            fieldsVal['file_label_' + lang + '_' + fileId] = resources[i].label && resources[i].label[lang]

            if (filex.label === undefined) {
              filex.label = {}
            }
            filex.label[lang] = resources[i].label && resources[i].label[lang]

            if (filex.path === undefined) {
              filex.path = {}
            }
            filex.path[lang] = resources[i].path && resources[i].path[lang]
          })

          fieldsVal['file_type_' + fileId] = resources[i].file_type

          filesTypes[fileId] = resources[i].file_type

          // prepare form values for field path and any language
          config.languages.forEach((lang, x) => {
            fieldsVal['file_path_' + lang + '_' + fileId] = resources[i].path && resources[i].path[lang]

            if (filesIds[fileId] === undefined) {
              filesIds[fileId] = {}
            }
            filesIds[fileId][lang] = resources[i].path && resources[i].path[lang]
          })

          filesArr.push(filex)

          console.log('setting form fields', filex, fieldsVal)
        }

        this.setState({ filesIds, filesTypes, filesArr, files: {} })
      }
      this.formRef.current.setFieldsValue(fieldsVal)
    }
  }

  _removeResource = (id) => {
    confirm('remove_real_file_question', this.props.language, () => {
      this.removeResource(id, () => {
        const filesArr = this.state.filesArr.filter(x => x._id !== id)
        this.setState({ filesArr })
      })
    })
  }

  addFile = (id, callAfterCreate) => {
  }

  removeSingleFile = (id, lang, callAfterCreate) => {
  }

  handleAddSingleFile = () => {
    this.addFile && this.addFile(this.props.data._id, (file) => {
      const filesArr = this.state.filesArr
      filesArr.push(file)

      this.setState({ filesArr })
    })
  }

  // render files form fields
  fileFieldsRender (resourcesDir, id, apiModuleName) {
    const { language } = this.props

    return (
      <>
        <Form.Item
          label={messageTypes[language].files}
          {...this.formItemLayout}
        >
          <Button onClick={this.handleAddSingleFile}>Dodaj plik</Button>
        </Form.Item>
        {
          this.state.filesArr.map((val, i) =>
            <Fragment key={i}>
              {i !== this.state.filesArr.length && <Divider />}
              <div className='file-remove-container'><Button onClick={() => { this._removeResource(val._id) }}>Usuń</Button></div>
              {
                config.languages.map((lang, ix) => {
                  return (
                    <Form.Item
                      name={'file_label_' + lang + '_' + val._id}
                      title={val._id}
                      label={messageTypes[language]['file_label_' + lang] + ' ' + (i + 1)}
                      {...this.formItemLayout}
                      rules={[{ required: true, message: messageTypes[language].field_required }]}
                      key={ix}
                    >
                      <Input onChange={(x) => {
                        const filesArr = this.state.filesArr
                        const field = filesArr.find(x => x._id === val._id)

                        field.label[lang] = x.target.value

                        this.setState({ filesArr })
                      }}
                      />
                    </Form.Item>
                  )
                })
              }

              <Form.Item
                name={'file_type_' + val._id}
                label={messageTypes[language].file_type + ' ' + (i + 1)}
                {...this.formItemLayout}
                rules={[{ required: true, message: messageTypes[language].field_required }]}
              >
                <Select onChange={x => this.handleFileTypeChange(x, val._id)}>
                  {this.fileTypes && this.fileTypes.map((ftype, i) =>
                    <Option key={i} value={ftype}>{ftype}</Option>)}
                </Select>
              </Form.Item>
              {config.languages.map((langx, ix) => {
                const _this = this

                return (
                  <Form.Item
                    name={'file_path_' + langx + '_' + val._id}
                    label={messageTypes[language]['file_' + langx] + ' ' + (i + 1)}
                    {...this.formItemLayout}
                    key={ix}
                    className='file-upload-input-container'
                    extra={
                      <Upload
                        name='file'
                        action={`${config.api_url}/admin/${apiModuleName}/resource/upload/${id}/${val._id}/${langx}`}
                        accept={this.state.filesTypes[val._id] ? '.' + this.state.filesTypes[val._id] : ''}
                        fileList={(val.path[langx] && [
                          {
                            uid: 1,
                            name: messageTypes[language].file
                          }
                        ]) || []}
                        headers={{
                          Authorization: 'Bearer ' + getCookie('_token')
                        }}
                        onRemove={() => {
                          return new Promise(function (resolve, reject) {
                            return confirm('remove_real_file_question', language, () => {
                              _this.removeSingleFile(val._id, langx, () => resolve(true))
                            }, () => resolve(false))
                          })
                        }}
                        onChange={(info) => {
                          if (info.file.status === 'done') {
                            const filesArr = this.state.filesArr
                            const file = filesArr.find(x => x._id === val._id)

                            file.path[langx] = true

                            this.setState({ filesArr })
                          } else if (info.file.status === 'error') {
                            console.log('error when uploading file')
                          } else if (info.file.status === 'removed') {
                            const filesArr = this.state.filesArr
                            filesArr[i].path[langx] = false

                            this.setState({ filesArr })
                          }
                        }}
                        maxCount={1}
                      >
                        {val.path[langx] !== true && <Button icon={<UploadOutlined />}>{messageTypes[language].send_file}</Button>}
                      </Upload>
                   }
                  />
                )
              })}
            </Fragment>
          )
}
      </>
    )
  }
}

export default ResourceFilesFormComponent
