import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr } from '../../../../actions/helpers'
import StyledStackForm from '../../../../styledComponents/sections/settings/stacks/stackForm'

class StacksModalSettings extends React.Component {
  state = {
    singleData: {},
    dataTitle: null
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    showAddRecordModal(false)
    setDataId(null)
    this.setDataTitle('')
  }

  setDataTitle = (dataTitle) => {
    this.setState({ dataTitle })
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData, dataTitle: singleData && singleData.name })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, overlordStacks
    } = this.props
    const { singleData, dataTitle } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && ((dataTitle && messageTypes[language].new_stack + ': ' + dataTitle) || messageTypes[language].new_stack)) ||
            (
              singleData &&
              messageTypes[language].stack) + ': ' + dataTitle
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledStackForm
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            stacks={overlordStacks}
            setDataTitle={this.setDataTitle}
          />
        </Modal>
      </div>
    )
  }
}

export default StacksModalSettings
