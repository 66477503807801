import React, { Fragment } from 'react'
import { numberToArray } from '../../actions/helpers'
import { messageTypes } from '../../actions/messages'
import config from '../../config'
import {
  Form, Select, Input
} from 'antd'

const { Option } = Select

class ResourceFilesFormComponent extends React.Component {
  fileTypes = ['pdf', 'zip', 'youtube']

  formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  state = {
    filesNum: []
  }

  // change number of input files
  changeInputsNumber = (e, key) => {
    const num = parseInt(e.target.value)
    if (num) {
      const obj = {}
      obj[key] = numberToArray(num)
      this.setState(obj)
    }
  }

  // on load set form default values
  componentDidMount () {
    this.setDefaultValues()
  }

  // update form data when record changed or new record will be added
  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  // prepare form data for existing record
  prepareFormExistingValues (data) {
    return {}
  }

  // prepare form data for new record
  prepareFormDefaultValues () {
    return {}
  }

  // prepare values when on form submit
  prepareFormValues () {
    return {}
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = this.prepareFormValues(values)

    obj.files = []
    for (let i = 1; i <= parseInt(values.number_of_files); i++) {
      const filesObj = {}
      filesObj.label = {}

      // prepare label fields for any language
      // eslint-disable-next-line
      config.languages.forEach((lang, x) => {
        filesObj.label[lang] = values['file_label_' + lang + '_' + i]
      })

      filesObj.file_type = values['file_type_' + i]
      filesObj.path = {}

      // prepare path fields for any language
      // eslint-disable-next-line
      config.languages.forEach((lang, x) => {
        filesObj.path[lang] = values['file_path_' + lang + '_' + i]
      })

      obj.files.push(filesObj)
    }
    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      this.setState({
        filesNum: numberToArray((data.files && data.files.length) || 0)
      }, () => {
        // get existing record data and add files required field
        const fieldsVal = Object.assign({
          number_of_files: data.files ? data.files.length : 0
        }, this.prepareFormExistingValues(data))

        if (data.files) {
          for (let i = 0; i < data.files.length; i++) {
            // prepare form values for field label and any language
            config.languages.forEach((lang, x) => {
              fieldsVal['file_label_' + lang + '_' + (i + 1)] = data.files[i].label && data.files[i].label[lang]
            })

            fieldsVal['file_type_' + (i + 1)] = data.files[i].file_type

            // prepare form values for field path and any language
            config.languages.forEach((lang, x) => {
              fieldsVal['file_path_' + lang + '_' + (i + 1)] = data.files[i].path && data.files[i].path[lang]
            })
          }
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      const fieldsVal = {}

      // clear files form fields
      for (let i = 0; i < this.formRef.current.getFieldValue('number_of_files'); i++) {
        fieldsVal['file_type_' + (i + 1)] = ''
        config.languages.forEach((lang, x) => {
          fieldsVal['file_label_' + lang + '_' + (i + 1)] = ''
          fieldsVal['file_path_' + lang + '_' + (i + 1)] = ''
        })
      }

      this.formRef.current.setFieldsValue(fieldsVal)

      this.setState({
        filesNum: []
      }, () => {
        // set default values of form and add files required field
        this.formRef.current.setFieldsValue(Object.assign({
          number_of_files: ''
        }, this.prepareFormDefaultValues()))
      })
    }
  }

  // render files form fields
  fileFieldsRender (resourcesDir) {
    const { language } = this.props
    const { filesNum } = this.state

    return (
      <>
        <Form.Item
          name='number_of_files'
          label={messageTypes[language].number_of_files}
          {...this.formItemLayout}
        >
          <Input
            onChange={(e) => this.changeInputsNumber(e, 'filesNum')}
            placeholder={messageTypes[language].number_of_files}
            maxLength={2}
          />
        </Form.Item>
        {
          filesNum.map((val, i) =>
            <Fragment key={i}>
              {
                config.languages.map((lang, ix) => {
                  return (
                    <Form.Item
                      name={'file_label_' + lang + '_' + (i + 1)}
                      label={messageTypes[language]['file_label_' + lang] + ' ' + (i + 1)}
                      {...this.formItemLayout}
                      key={ix}
                    >
                      <Input />
                    </Form.Item>
                  )
                })
              }

              <Form.Item
                name={'file_type_' + (i + 1)}
                label={messageTypes[language].file_type + ' ' + (i + 1)}
                {...this.formItemLayout}
              >
                <Select>
                  {this.fileTypes && this.fileTypes.map((ftype, i) =>
                    <Option key={i} value={ftype}>{ftype}</Option>)}
                </Select>
              </Form.Item>

              {
                config.languages.map((lang, ix) => {
                  return (
                    <Form.Item
                      name={'file_path_' + lang + '_' + (i + 1)}
                      label={messageTypes[language]['file_path_' + lang] + ' ' + (i + 1)}
                      {...this.formItemLayout}
                      key={ix}
                    >
                      <Input placeholder={
                        messageTypes[language].file_in_dir + ' resources/' + resourcesDir + ' ' +
                        messageTypes[language].file_path_placeholder.replace('%LANG%', lang)
                      }
                      />
                    </Form.Item>
                  )
                })
              }
            </Fragment>
          )
        }
      </>
    )
  }
}

export default ResourceFilesFormComponent
