import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'

const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledStackDescriptionsForm extends React.Component {
  formRef = React.createRef()
  state = {
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.desc = {}
    obj.desc.pl = values.desc_pl
    obj.desc.en = values.desc_en
    obj.map_legend = {}
    obj.map_legend.pl = values.map_legend_pl
    obj.map_legend.en = values.map_legend_en
    obj.stack_type = values.stack_type
    obj.ca_certificates = values.ca_certificates

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      this.setState({}, () => {
        const fieldsVal = {
          desc_pl: data.desc.pl,
          desc_en: data.desc.en,
          map_legend_pl: data.map_legend ? data.map_legend.pl : '',
          map_legend_en: data.map_legend ? data.map_legend.en : '',
          stack_type: data.stack_type,
          ca_certificates: data.ca_certificates
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
      }, () => {
        this.formRef.current.setFieldsValue({
          desc_pl: '',
          desc_en: '',
          map_legend_pl: '',
          map_legend_en: '',
          stack_type: null,
          ca_certificates: []
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl + ' (MD)'}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <TextArea rows={8} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en + ' (MD)'}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <TextArea rows={8} />
        </Form.Item>

        <Form.Item
          name='map_legend_pl'
          label={messageTypes[language].map_legend_pl + ' (MD)'}
          {...formItemLayout}
        >
          <TextArea rows={8} />
        </Form.Item>

        <Form.Item
          name='map_legend_en'
          label={messageTypes[language].map_legend_en + ' (MD)'}
          {...formItemLayout}
        >
          <TextArea rows={8} />
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              settings.stack_types.length
                ? settings.stack_types.map((stackType, n) =>
                  <Option key={n} value={stackType}>{stackType}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='ca_certificates'
          label={messageTypes[language].ca_certificates}
          {...formItemLayout}
        >
          <Select mode='multiple'>
            {
              settings.ca_certificates.length
                ? settings.ca_certificates.map((cert, n) =>
                  <Option key={n} value={cert}>{cert}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledStackDescriptionsForm
