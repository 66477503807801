import React, { Component } from 'react'
import { Menu, Dropdown/*, Icon */ } from 'antd'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../actions/messages'
class StyledFilesDownloader extends Component {
  constructor (props) {
    super(props)
    this.icons = {
      pdf: 'file-pdf-o',
      bin: 'file-code-o',
      zip: 'file-zip-o',
      youtube: 'youtube-play'
    }
  }

  render () {
    const { language, downloadFile, element, modal, customLabel } = this.props

    const menu = (
      <Menu>
        {
          element.files && element.files.length && element.files.map((val, i) =>
            <Menu.Item key={i} onClick={() => val.path && val.path[language] && val.file_type !== 'youtube' ? downloadFile(element, (!this.props.useFileId && val.path && val.path[language]) || val._id, language, val) : null}>
              {val.file_type === 'youtube'
                ? (
                  <a
                    target={val.path && val.path[language] ? '_blank' : ''}
                    rel='noopener noreferrer'
                    className='files-downloader-yt'
                    href={val.path && val.path[language] ? (val.path[language]) : '#'}
                  >
                    <FontAwesomeIcon name={this.icons[val.file_type] || 'file-o'} />&nbsp;&nbsp;{val.path && val.path[language] ? val.label && val.label[language] : messageTypes[language].no_file}
                  </a>
                  )
                : (
                  <span>
                    <FontAwesomeIcon name={this.icons[val.file_type] || 'file-o'} />&nbsp;&nbsp;{val.path && val.path[language] ? val.label && val.label[language] : messageTypes[language].no_file}
                  </span>
                  )}
              {/* <a class="scenario-file-viewer" href={val.path && val.path[language] ? '/scenarios/file-viewer/' + scenarioId + '/' + (val.path && val.path[language]) : null } target="_blank" rel="noopener noreferrer">
            <Icon type="export" />
            </a> */}
            </Menu.Item>
          )
        }
      </Menu>
    )

    return (
      (element.demo && !modal && <p className='premium-version-only'>{messageTypes[language].premium_version_only}</p>) ||
      ((element.files && element.files.length &&
        (
          <Dropdown overlay={menu} className='files-downloader'>
            <a className='ant-dropdown-link' href='#download' onClick={(e) => { e.preventDefault(); return false }}>
              <FontAwesomeIcon name='download' />&nbsp;&nbsp;{customLabel || messageTypes[language].materials}
            </a>
          </Dropdown>
        )) || ''
      )
    )
  }
}

export default StyledFilesDownloader
