import settings from '../../../config/'
import MapObjectModal from './mapObjectModal'

class MachineModal extends MapObjectModal {
  constructor () {
    super()
    this.state = {
      machineId: null,
      consoleOpen: false,
      machinesRefreshInterval: null
    }
  }

  getElementId = (element) => {
    return element.machine_name
  }

  componentDidMount () {
    // set interval for refreshing if ajax refresh is enabled
    if (settings.ajax_refresh_rate) {
      const machinesRefreshInterval = setInterval(() => {
        this.props.getAllMachines()
      }, settings.ajax_refresh_rate * 1000)
      this.setState({ machinesRefreshInterval })
    }
  }

  componentWillUnmount () {
    if (settings.ajax_refresh_rate) {
      clearInterval(this.state.machinesRefreshInterval)
    }
  }
}

export default MachineModal
