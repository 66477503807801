import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider, InputNumber
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledFlagForm extends React.Component {
  formRef = React.createRef()

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.name = values.name
    obj.value = values.value
    obj.score = values.score
    obj.stack_type = values.stack_type

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      this.setState({}, () => {
        const fieldsVal = {
          name: data.name,
          value: data.value,
          score: data.score,
          stack_type: data.stack_type
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
      }, () => {
        this.formRef.current.setFieldsValue({
          name: '',
          value: '',
          score: 0,
          stack_type: ''
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='name'
          label={messageTypes[language].name_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='value'
          label={messageTypes[language].value}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='score'
          label={messageTypes[language].score}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              settings.stack_types.length
                ? settings.stack_types.map((stackType, n) =>
                  <Option key={n} value={stackType}>{stackType}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledFlagForm
