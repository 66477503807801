import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr } from '../../../../actions/helpers'
import StyledStackDescsForm from '../../../../styledComponents/sections/settings/stackDescriptions/stackDescriptionForm'

class StacksModalSettings extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    showAddRecordModal(false)
    setDataId(null)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, machines
    } = this.props
    const { singleData } = this.state
    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_stack_description) ||
            (
              singleData &&
              messageTypes[language].stack_type_description) + ': ' + (singleData && singleData.stack_type)
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledStackDescsForm
            machines={machines}
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
          />
        </Modal>
      </div>
    )
  }
}

export default StacksModalSettings
