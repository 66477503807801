import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider, InputNumber, DatePicker, Switch, Checkbox
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'
import ResourceFilesFormComponent from '../../../common/filesWithUploadForm'
import moment from 'moment'
import dateformat from 'dateformat'

import 'moment/locale/pl'
import datePickerPL from 'antd/es/date-picker/locale/pl_PL'
import datePickerEN from 'antd/es/date-picker/locale/en_US'

const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledChallengeForm extends ResourceFilesFormComponent {
  formRef = React.createRef()

  prepareFormValues (values) {
    return {
      name: { pl: values.name_pl, en: values.name_en },
      desc: { pl: values.desc_pl, en: values.desc_en },
      rorder: values.rorder,
      challenge_category: values.challenge_category,
      stack_type: values.stack_type,
      level: values.level,
      publication_date: dateformat(values.publication_date, 'yyyy-mm-dd HH:MM'),
      delayed_publication: values.delayed_publication,
      solve_end_date: dateformat(values.solve_end_date, 'yyyy-mm-dd HH:MM'),
      solve_time_limit: values.solve_time_limit,
      active: values.active,
      flag: values.flag,
      flag_unique_per_user: values.flag_unique_per_user,
      flag_case_insensitive: values.flag_case_insensitive,
      score: values.score
    }
  }

  prepareFormExistingValues (data) {
    this.setState({
      stackType: data.stack_type,
      delayedPublication: data.delayed_publication,
      solveTimeLimit: data.solve_time_limit,
      active: data.active
    })

    return {
      name_pl: data.name.pl,
      name_en: data.name.en,
      desc_pl: data.desc.pl,
      desc_en: data.desc.en,
      rorder: data.rorder,
      challenge_category: data.challenge_category?._id,
      stack_type: data.stack_type,
      level: data.level,
      publication_date: data.publication_date && moment(data.publication_date),
      delayed_publication: data.delayed_publication,
      solve_end_date: data.solve_end_date && moment(data.solve_end_date),
      solve_time_limit: data.solve_time_limit,
      active: data.active,
      flag: data.flag,
      flag_unique_per_user: data.flag_unique_per_user,
      flag_case_insensitive: data.flag_case_insensitive,
      score: data.score
    }
  }

  handleStackTypeChange = (stackType) => {
    this.setState({ stackType })
  }

  handleActiveChanged = (event) => {
    this.setState({ active: event.target.checked })
  }

  handleFlagsChange = (flag) => {
    this.setState({ flag }, () => {
      this.formRef.current?.validateFields(['active'])
    })
  }

  addFile = (challengeId, callAfterCreate) => {
    const { addFile } = this.props
    addFile && addFile(challengeId).then(result => callAfterCreate(result))
  }

  removeSingleFile = (challengeId, lang, callAfterCreate) => {
    const { removeSingleFile } = this.props
    removeSingleFile && removeSingleFile(challengeId, lang).then(result => callAfterCreate && callAfterCreate(result))
  }

  removeResource = (challengeId, callAfterCreate) => {
    const { removeResource } = this.props
    removeResource && removeResource(challengeId).then(result => callAfterCreate && callAfterCreate(result))
  }

  render () {
    const { language, loading, challengeCategories, data } = this.props
    const { delayedPublication, solveTimeLimit } = this.state
    const locale = (language === 'en' && datePickerEN) || datePickerPL

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='level'
          label={messageTypes[language].challenge_level}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              [1, 2, 3].map((level, n) =>
                <Option key={n} value={level}>
                  <img style={{ height: '14px' }} src={'/images/icons/' + level + 'stars_clean.svg'} alt={level} />
                </Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='rorder'
          label={messageTypes[language].order}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name='challenge_category'
          label={messageTypes[language].category}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {challengeCategories.map((element, n) =>
              <Option key={n} value={element._id}>{element.name[language]}</Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select onChange={this.handleStackTypeChange}>
            {
              settings.stack_types.length
                ? settings.stack_types.map((stackType, n) =>
                  <Option key={n} value={stackType}>{stackType}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='active'
          label={messageTypes[language].active2}
          valuePropName='checked'
          {...this.formItemLayout}
          extra={!this.state.active ? messageTypes[language].not_active_challenges_not_in_ranking : ''}
        >
          <Checkbox
            defaultChecked={data.active || ''}
            onChange={this.handleActiveChanged}
          />
        </Form.Item>

        <Form.Item
          name='flag'
          label={messageTypes[language].flag}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='score'
          label={messageTypes[language].score}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name='flag_unique_per_user'
          label={messageTypes[language].flag_unique_per_user}
          valuePropName='checked'
          {...formItemLayout}
        >
          <Checkbox defaultChecked={data.flag_unique_per_user || ''} />
        </Form.Item>

        <Form.Item
          name='flag_case_insensitive'
          label={messageTypes[language].flag_case_insensitive}
          valuePropName='checked'
          {...formItemLayout}
        >
          <Checkbox defaultChecked={data.flag_case_insensitive || ''} />
        </Form.Item>

        <Form.Item
          name='delayed_publication'
          label={messageTypes[language].delayed_publication}
          {...formItemLayout}
          extra={!this.state.active ? messageTypes[language].set_active_to_enable_publication : ((!delayedPublication && messageTypes[language].publication_on_competition_start) || '')}
        >
          <Switch checked={delayedPublication} onChange={(x) => this.setState({ delayedPublication: x })} />
        </Form.Item>

        {delayedPublication &&
          <Form.Item
            name='publication_date'
            label={messageTypes[language].publication_date}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <DatePicker showTime format='YYYY-MM-DD HH:mm' minuteStep={5} locale={locale} />
          </Form.Item>}

        <Form.Item
          name='solve_time_limit'
          label={messageTypes[language].solve_time_limit}
          {...formItemLayout}
          extra={solveTimeLimit ? messageTypes[language].challenge_can_be_viewed_but_without_points : ''}
        >
          <Switch checked={solveTimeLimit} onChange={(x) => this.setState({ solveTimeLimit: x })} />
        </Form.Item>

        {solveTimeLimit &&
          <Form.Item
            name='solve_end_date'
            label={messageTypes[language].solve_end_date}
            rules={[
              { required: true, message: messageTypes[language].field_required },
              {
                message: messageTypes[language].solve_time_limit_cannot_be_lesser_than_publication_date,
                validator: (_, value) => {
                  if (this.formRef.current?.getFieldValue('delayed_publication') && value < this.formRef.current?.getFieldValue('publication_date')) {
                    return Promise.reject(new Error('date of sending solves cannot be before publication date'))
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
            {...formItemLayout}
          >
            <DatePicker showTime format='YYYY-MM-DD HH:mm' minuteStep={5} locale={locale} />
          </Form.Item>}

        {this.fileFieldsRender('files', data._id, 'challenges')}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledChallengeForm
