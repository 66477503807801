import React, { Component } from 'react'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'
import StyledMapObjectModal from '../../../styledComponents/sections/home/mapObjectModal'

const FileDownload = require('js-file-download')

class MapObjectModal extends Component {
  onHandleClose = (e) => {
    this.props.toggleModal(false, null)
  }

  getElementId = (element) => {
    return element._id
  }

  prepareFilename = (element, stack, filename) => {
    return filename
  }

  setMachineCardTitle = () => {
    return null
  }

  downloadFile = (element, filename) => {
    const { downloadFile, language, stack } = this.props

    // determine filename for downloaded file
    filename = this.prepareFilename(element, stack, filename)

    // determine which field get as element id
    const elementId = this.getElementId(element)

    downloadFile(elementId, getCookie('_token'), filename).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, filename)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  getFileDownloadElement = (element) => {
    const { language } = this.props

    return (element.files && element.files.length > 0
      ? (
        <StyledFilesDownloader
          key='files_downloader'
          language={language}
          element={element}
          downloadFile={this.downloadFile}
        />)
      : '')
  }

  render () {
    const { element, machineElement, language, visible, getAllMachines, setAllowStateStatus, allowStateStatus, stack, clearAllowMachineStateStatus } = this.props

    return (
      (element)
        ? (
          <StyledMapObjectModal
            element={element}
            stack={stack}
            machineElement={machineElement}
            machineCardTitle={this.setMachineCardTitle(element)}
            language={language}
            visible={visible}
            downloadFile={this.downloadFile}
            handleClose={this.onHandleClose}
            getAllMachines={getAllMachines}
            setAllowStateStatus={setAllowStateStatus}
            clearAllowMachineStateStatus={clearAllowMachineStateStatus}
            allowStateStatus={allowStateStatus}
            handleConsole={this.toggleConsole}
            getFileDownloadElement={this.getFileDownloadElement}
          />
          )
        : ''
    )
  }
}

export default MapObjectModal
