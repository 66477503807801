import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledChallengesTableSettings from '../../../../styledComponents/sections/settings/challenges/table'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import ChallengeModalSettings from './modal'
import ChallengeDetailsModal from './detailsModal'
import { CheckOutlined } from '@ant-design/icons'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr,
  confirm
} from '../../../../actions/helpers'
import {
  adminGetChallenges,
  adminCreateChallenge,
  adminGetChallengeCategories,
  adminUpdateChallenge,
  adminDeleteChallenge,
  adminCreateChallengeResource,
  adminGetChallengeResources,
  adminDeleteChallengeResourceFile,
  adminDeleteChallengeResource
} from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import moment from 'moment'

class ChallengesSettings extends Component {
  state = {
    data: [],
    resources: [],
    challengeCategories: [],
    dataId: null,
    showDataId: null,
    loading: false
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
    this.getChallengeCategories()
  }

  getChallengeResources = (id) => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminGetChallengeResources(id, getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              resources: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of challenge resources failed'))
          }
        }
      })
    })
  }

  setDataId = (dataId) => {
    if (dataId !== null) {
      this.getChallengeResources(dataId).then(() => {
        this.setState({ dataId })
      })
    } else {
      this.setState({ dataId })
    }
  }

  setShowDataId = (showDataId) => { this.setState({ showDataId }) }
  showAddRecordModal = (flag) => {
    if (flag) {
      this.addRecord({ name: { pl: 'Draft' }, level: 1, rorder: 0, score: 0 })
    }
  }

  getAllData = () => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminGetChallenges(getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              data: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of challenges failed'))
          }
        }
      })
    })
  }

  getChallengeCategories = () => {
    const { language } = this.props
    adminGetChallengeCategories(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            challengeCategories: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_challenge_question', language, () => {
      adminDeleteChallenge(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })

    adminUpdateChallenge(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
          this.setState({
            dataId: null
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminCreateChallenge(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData().then(() => this.setDataId(json.response._id))
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addFile = (id) => {
    const { language } = this.props
    const _this = this
    this.setState({ loading: true })

    return new Promise(function (resolve, reject) {
      adminCreateChallengeResource(id, getCookie('_token')).then((json) => {
        _this.setState({ loading: false })
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('create of challenge failed'))
        }
      })
    })
  }

  removeSingleFile = (id, lang, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteChallengeResourceFile(id, lang, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of challenge resources file failed'))
        }
      })
    })
  }

  removeResource = (id, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteChallengeResource(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of challenge resource failed'))
        }
      })
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name && val.name[lang]
      obj.level = val.level
      obj.rorder = val.rorder !== undefined ? val.rorder : ''
      obj.challenge_category = val.challenge_category?.name && val.challenge_category.name[lang]
      obj.stack_type = val.stack_type
      obj.active = val.active
      obj.active_icon = val.active ? <CheckOutlined /> : ''
      obj.added_to_map = val.added_to_map
      obj.added_to_map_icon = val.added_to_map ? <CheckOutlined /> : ''
      obj.publication_time = (!val.active && messageTypes[this.props.language].inactive) || ((val.delayed_publication && moment(val.publication_date)) || messageTypes[this.props.language].ctf_start)
      obj.solve_end_date = (val.solve_time_limit && moment(val.solve_end_date)) || '-'
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, showDataId, challengeCategories } = this.state
    const { language } = this.props

    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_challenge}
        />
        <StyledChallengesTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
          setShowDataId={this.setShowDataId}
        />
        <ChallengeModalSettings
          language={language}
          challengeCategories={challengeCategories}
          data={data}
          dataId={dataId}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addFile={this.addFile}
          removeSingleFile={this.removeSingleFile}
          removeResource={this.removeResource}
          resources={this.state.resources}
        />
        <ChallengeDetailsModal
          language={language}
          data={data}
          dataId={showDataId}
          setShowDataId={this.setShowDataId}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const ChallengesSettingsContainer = connect(
  mapStateToProps
)(ChallengesSettings)

export default ChallengesSettingsContainer
