import React, { Component } from 'react'
import ResourcesVisualiser from '../../common/resourcesVisualiser'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Collapse } from 'antd'
import { messageTypes } from '../../../actions/messages'

const { Panel } = Collapse

class StyledCardBody extends Component {
  componentDidMount () {
    this._scrollBarRef.updateScroll()
  }

  render () {
    const { insideMap, desc, element, language, downloadMachineFile } = this.props
    const brDesc = desc.split('\n')

    return (
      <div className={`machine-card-body${element.files && element.files.length > 0 ? ' machine-card-with-materials' : ''}`}>
        <PerfectScrollbar ref={(ref) => { this._scrollBarRef = ref }}>
          <div className='machine-card-description'>
            {element.active_blocks?.length > 0 &&
              <Collapse className='collapse-blocked-item'>
                <Panel className='item-blocked' header={messageTypes[language].active_reservations + ': ' + element.active_blocks?.length} key='1'>
                  {element.active_blocks?.map((item, idx) => {
                    return <p key={idx}>{messageTypes[language].reserved_to} {item.blocked_to} {messageTypes[language].reserved_by} {item.user?.name}<br />{messageTypes[language].scenario}: {item.scenario?.name[language]}</p>
                  })}
                </Panel>
              </Collapse>}
            {
              brDesc.map((val, i) => (
                <div key={i}>{val} <br /></div>
              ))
            }
          </div>
        </PerfectScrollbar>
        {!insideMap && element.files && element.files.length > 0
          ? (
            <div className='machine-card-materials'>
              <StyledFilesDownloader
                key='files_downloader'
                language={language}
                element={element}
                downloadFile={downloadMachineFile}
              />
            </div>
            )
          : ''}
        {!element.disabled &&
          (
            <ResourcesVisualiser resources={element.resources} />
          )}
      </div>
    )
  }
}

export default StyledCardBody
