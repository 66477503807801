import React, { Component } from 'react'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

class AddRecordButton extends Component {
  state = {
    visible: false
  }

  componentDidMount () {
    this.setState({ visible: true })
  }

  render () {
    const { showAddRecordModal, title } = this.props
    return (
      <div className={`add-record-button ${this.state.visible && 'fade-in'}`}>
        <Button onClick={() => { showAddRecordModal(true) }}>
          <PlusCircleOutlined /> {title}
        </Button>
      </div>
    )
  }
}

export default AddRecordButton
