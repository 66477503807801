import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr } from '../../../../actions/helpers'
import StyledChallengeCategoryForm from '../../../../styledComponents/sections/settings/challengeCategories/form'

class ChallengeCategoryModalSettings extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord
    } = this.props
    const { singleData } = this.state
    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_challenge_category) ||
            (
              singleData &&
              singleData.name &&
              singleData.name[language])
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledChallengeCategoryForm
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
          />
        </Modal>
      </div>
    )
  }
}

export default ChallengeCategoryModalSettings
