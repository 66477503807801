import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'antd'
import { setScenarioElement, setScenarioModalVisible } from '../../../store/actions'

import StyledCardBody from '../../../styledComponents/sections/scenarios/cardBody'
import StyledStarsDisplay from '../../../styledComponents/sections/scenarios/starsDisplay'
import StyledTagsDisplay from '../../../styledComponents/common/tagsDisplay'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'

import { messageTypes } from '../../../actions/messages'

class ScenariosCard extends Component {
  componentDidMount () {
    this._isMounted = true
  }

  modalPopup = (scenarioElement) => {
    this.props.setScenarioElement(scenarioElement)
    this.props.setScenarioModalVisible(true)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { element, downloadScenarioFile } = this.props
    const { difficulty, s_type: sType } = element
    const { language } = this.props

    const name = element.name[language] || messageTypes[language].scenario_no_name

    return (
      <Card
        title={
          <div>
            {sType === 'presentation'
              ? (name.length <= 10 ? <StyledTagsDisplay tags={[messageTypes[language]['scenario_type_' + sType]]} /> : '')
              : <StyledStarsDisplay difficulty={difficulty} />}
            <span className='header-title'>{name}</span>
          </div>
        }
        actions={[
          <StyledFilesDownloader
            key='fdownload'
            language={language}
            element={element}
            downloadFile={downloadScenarioFile}
          />
        ]}
      >
        <StyledCardBody
          handleClick={this.modalPopup}
          language={language}
          element={element}
        />
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  scenarioElement: state.hdStore.scenarioElement,
  scenarioModalVisible: state.hdStore.scenarioModalVisible
})

const mapDispatchToProps = {
  setScenarioElement,
  setScenarioModalVisible
}

const ScenariosCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenariosCard)

export default ScenariosCardContainer
