import React from 'react'
import { Button } from 'antd'
import MapObjectModal from './mapObjectModal'
import { messageTypes } from '../../../actions/messages'
import { slugify } from '../../../actions/helpers'

class VpnModal extends MapObjectModal {
  prepareFilename = (element, stack, filename) => {
    return element && stack && element.network_name ? 'hd-' + element.network_name + '-' + slugify(stack.name) + '.ovpn' : 'vpn-hackingdept.ovpn'
  }

  getFileDownloadElement = (element) => {
    const { language } = this.props

    return (
      !element.disabled &&
        <Button style={{ float: 'left' }} key='download' onClick={() => { this.downloadFile(element) }}>{messageTypes[language].download_conf}</Button>
    )
  }

  setMachineCardTitle = (element) => {
    const { language } = this.props
    return element.name && element.name[language]
  }
}

export default VpnModal
