import React from 'react'
import Highlighter from 'react-highlight-words'
import { Table, Divider, Input, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../../actions/messages'

class StyledChallengeCategoryTableSettings extends React.Component {
  state = {
    columns: []
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  setColumns (language) {
    this.setState({
      columns: [{
        title: messageTypes[language].name_name,
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
        sortDirections: ['ascend', 'descend'],
        ...this.getColumnSearchProps('name', language)
      }, {
        title: messageTypes[language].stack_type,
        dataIndex: 'stack_type',
        key: 'stack_type',
        sorter: (a, b) => { return (!a.stack_type && 1) || a.stack_type.localeCompare(b.stack_type) },
        sortDirections: ['ascend', 'descend'],
        ...this.getColumnSearchProps('stack_type', language)
      }, {
        title: messageTypes[language].order,
        dataIndex: 'rorder',
        key: 'rorder',
        sorter: (a, b) => { return a.rorder - b.rorder }
      }, {
        title: messageTypes[language].actions,
        key: 'action',
        render: (text, record) => (
          <span>
            <a href='#edit' className='edit-btn' onClick={() => { this.props.setDataId(record._id) }}>{messageTypes[language].edit}</a>
            <Divider type='vertical' />
            <a href='#delete' className='delete-btn' onClick={() => { this.props.deleteRecord(record._id) }}>{messageTypes[language].delete}</a>
          </span>
        )
      }]
    })
  }

  componentDidMount () {
    const { language } = this.props
    this._isMounted = true
    this.setColumns(language)
  }

  componentDidUpdate (prevProps, prevState) {
    const { language } = this.props
    if (this._isMounted && language !== prevProps.language) {
      this.setColumns(language)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { columns } = this.state
    const { data, language } = this.props

    return (
      <Table
        className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
        {...this.settings}
        columns={columns}
        dataSource={data}
        locale={{
          triggerDesc: messageTypes[language].table_sort_desc,
          triggerAsc: messageTypes[language].table_sort_asc,
          cancelSort: messageTypes[language].table_sort_cancel
        }}
      />
    )
  }
}

export default StyledChallengeCategoryTableSettings
