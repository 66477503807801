import React, { Component } from 'react'
import { Menu, Dropdown, Button } from 'antd'
import { ClusterOutlined, DownOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'
import { connect } from 'react-redux'

class StackActionDropdown extends Component {
  render () {
    const { stack, disabled, language, executeStackAction } = this.props

    const menu = (
      <Menu>
        <Menu.Item
          key='revert'
          onClick={() => executeStackAction(stack._id, 'revert')}
        >
          <span>{messageTypes[language].revert}</span>
        </Menu.Item>
        <Menu.Item
          key='revert-with-poweron'
          onClick={() => executeStackAction(stack._id, 'revert-with-poweron')}
        >
          <span>{messageTypes[language].revert_and_poweron}</span>
        </Menu.Item>
      </Menu>
    )

    return (
      stack !== 'switching'
        ? (
          <Dropdown overlay={menu} disabled={disabled}>
            <Button>
              <ClusterOutlined /> {messageTypes[language].current_stack} <DownOutlined />
            </Button>
          </Dropdown>
          )
        : false
    )
  }
}

const mapStateToProps = state => ({
  stack: state.hdStore.stack
})

const StackActionDropdownContainer = connect(
  mapStateToProps
)(StackActionDropdown)

export default StackActionDropdownContainer
