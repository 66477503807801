import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import ResourceFilesFormComponent from '../../../common/filesForm'
import {
  Form, Select, Input, InputNumber, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
const { Option } = Select
const { TextArea } = Input

class StyledMachinesForm extends ResourceFilesFormComponent {
  formRef = React.createRef()

  prepareFormValues (values) {
    return {
      desc: { pl: values.desc_pl, en: values.desc_en },
      machine_name: values.machine_name,
      order: values.order
    }
  }

  prepareFormExistingValues (data) {
    return {
      desc_pl: data.desc ? data.desc.pl : '',
      desc_en: data.desc ? data.desc.en : '',
      machine_name: data.machine_name,
      order: data.order ? data.order : 0
    }
  }

  prepareFormDefaultValues () {
    return {
      desc_pl: '',
      desc_en: '',
      machine_name: '',
      order: 0
    }
  }

  render () {
    const { language, loading, machines } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='machine_name'
          label={messageTypes[language].machine_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select placeholder={messageTypes[language].machines}>
            {
              machines.map((vname, i) => (
                <Option key={i} value={vname.toLowerCase()}>{vname}</Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='order'
          label={messageTypes[language].order}
          {...this.formItemLayout}
        >
          <InputNumber min={0} max={255} />
        </Form.Item>

        {this.fileFieldsRender('machines')}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledMachinesForm
