import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import settings from '../../../../config/'

import {
  Form, Input, Button, Divider, Select, Checkbox
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Colorpicker } from 'antd-colorpicker'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledUserForm extends React.Component {
  state = {
    userStacks: [],
    useExistingStack: true
  }

  formRef = React.createRef()

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.name = values.name
    obj.email = values.email
    obj.role = values.role
    obj.stack = values.stack
    obj.stacks = values.stacks
    obj.color = values.color?.hex

    if (values.password) {
      obj.password = values.password
    }
    if (values.password_repeat) {
      obj.password_repeat = values.password_repeat
    }
    if (isNewRecord) {
      obj.create_new_stack = values.create_new_stack
      obj.new_stack_type = values.new_stack_type
    }

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data } = this.props

    if (data) {
      this.formRef.current.setFieldsValue({
        name: data.name,
        email: data.email,
        // progress_quizzes: data.progress_quizzes,
        // solved_quizzes: data.solved_quizzes,
        password: '',
        stack: data.stacks && data.stacks.includes(data.stack) ? data.stack : null,
        stacks: data.stacks,
        role: data.role,
        color: { hex: data.color || '#ffffff' }
      })

      this.setState({ userStacks: data.stacks })
    } else {
      this.formRef.current.setFieldsValue({
        color: { hex: '#ffffff' }
      })
    }
  }

  handleStacksChange = (value) => {
    this.setState({ userStacks: value })

    if (!this.formRef.current.getFieldValue('stack') || this.formRef.current.getFieldValue('stacks').indexOf(this.formRef.current.getFieldValue('stack')) === -1) {
      this.formRef.current.setFieldsValue({ stack: null })
    }
  }

  handleStackChange = (value) => {
    this.setState({ useExistingStack: !value.target.checked })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps) {
    const { data } = this.props
    if (data._id !== prevProps.data._id) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading, isNewRecord, stacks, isSuperAdmin } = this.props
    const { userStacks, useExistingStack } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef} initialValues={{ create_new_stack: false }}>
        <Form.Item
          name='name'
          label={messageTypes[language].login_name}
          rules={[{ required: true, message: messageTypes[language].login_name_err }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='email'
          label={messageTypes[language].login_email}
          rules={[{ required: true, message: messageTypes[language].login_email_err }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='password'
          label={messageTypes[language].login_password}
          rules={isNewRecord ? [{ required: true, message: messageTypes[language].login_password_err }] : []}
          {...formItemLayout}
        >
          <Input.Password />
        </Form.Item>
        {isNewRecord
          ? (
            <Form.Item
              name='password_repeat'
              label={messageTypes[language].login_password_repeat}
              rules={[{ required: true, message: messageTypes[language].login_password_err }]}
              {...formItemLayout}
            >
              <Input.Password />
            </Form.Item>
            )
          : ''}
        <Form.Item
          name='role'
          label={messageTypes[language].role}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              settings.roles.length
                ? settings.roles.map((element, n) =>
                  <Option key={n} value={element.role}>{messageTypes[language]['role_' + element.name]}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='color'
          label={messageTypes[language].map_color}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Colorpicker popup onColorResult={(color) => { return { hex: color.hex } }} />
        </Form.Item>

        {useExistingStack || !isNewRecord
          ? (
            <>
              <Form.Item
                name='stacks'
                label={messageTypes[language].stacks}
                rules={[{ required: true, message: messageTypes[language].field_required }]}
                {...formItemLayout}
              >
                <Select mode='multiple' onChange={this.handleStacksChange}>
                  {
                    stacks && stacks.length
                      ? stacks.map((element, n) =>
                        <Option key={n} value={element._id}>{element.name} ({element.type})</Option>
                      )
                      : ''
                  }
                </Select>
              </Form.Item>
              <Form.Item
                name='stack'
                label={messageTypes[language].default_stack}
                rules={[{ required: true, message: messageTypes[language].field_required }]}
                {...formItemLayout}
              >
                <Select>
                  {
                    stacks && stacks.length && userStacks
                      ? stacks.filter(x => userStacks.includes(x._id) && x.demo !== 'full').map((element, n) =>
                        <Option key={n} value={element._id}>{element.name} ({element.type})</Option>
                      )
                      : ''
                  }
                </Select>
              </Form.Item>
            </>
            )
          : ''}
        {isNewRecord && isSuperAdmin
          ? (
            <>
              <Form.Item
                name='create_new_stack'
                label={messageTypes[language].create_new_stack}
                valuePropName='checked'
                {...formItemLayout}
              >
                <Checkbox onChange={this.handleStackChange} />
              </Form.Item>

              {!useExistingStack
                ? (
                  <Form.Item
                    name='new_stack_type'
                    label={messageTypes[language].stack_type}
                    rules={[{ required: true, message: messageTypes[language].field_required }]}
                    {...formItemLayout}
                  >
                    <Select>
                      {
                        settings.stack_types.length
                          ? settings.stack_types.map((stackType, n) =>
                            <Option key={n} value={stackType}>{stackType}</Option>
                          )
                          : ''
                      }
                    </Select>
                  </Form.Item>
                  )
                : ''}
            </>
            )
          : ''}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledUserForm
