import React, { Component } from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../actions/messages'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'

class MapLegendModal extends Component {
  state = {
    loading: false
  }

  handleCancel = () => {
    this.props.handleMapLegendVisibility(false)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language, visible, stack } = this.props

    return (
      <div>
        <Modal
          visible={visible}
          title={messageTypes[language].map_legend}
          onCancel={this.handleCancel}
          footer={null}
          className='map-legend-modal'
          width={700}
        >
          <ReactMarkdown>{stack && stack.map_legend && stack.map_legend[language]}</ReactMarkdown>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stack: state.hdStore.stack
})
const WrappedMapLegendModalContainer = connect(
  mapStateToProps
)(MapLegendModal)

export default WrappedMapLegendModalContainer
