import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMapsTable from '../../../../styledComponents/sections/settings/maps/mapsTable'
import settings from '../../../../config/'

class Maps extends Component {
  state = {
    data: settings.stack_types
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj.type = val

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data } = this.state
    const { language } = this.props

    return (
      <StyledMapsTable
        language={language}
        data={this.returnWithKey(data, language)}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MapsContainer = connect(
  mapStateToProps
)(Maps)

export default MapsContainer
