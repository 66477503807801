import React from 'react'
import {
  Form, Input, Button
} from 'antd'
import { FlagOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../actions/messages'
import { quizAnswerPost } from '../../../actions'
import { getCookie, openNotificationPopup } from '../../../actions/helpers'

class QuizAnswerForm extends React.Component {
  formRef = React.createRef()

  componentDidMount () {
    this._isMounted = true
  }

  handleSubmit = (values) => {
    const {
      quizId,
      quizNumber,
      language,
      setQuizNumber,
      setSolved,
      setUnsolved,
      handleListQuit
    } = this.props

    const token = getCookie('_token')
    const data = { quizNumber, answer: values.flag }

    quizAnswerPost(quizId, data, token).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.formRef.current.resetFields()
          setSolved(quizNumber)
          setQuizNumber(null)
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        } else if (json.status === 'success') {
          this.formRef.current.resetFields()
          setSolved(quizNumber)
          setQuizNumber(null)
          handleListQuit()
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        } else if (json.status === 'err') {
          setUnsolved(quizNumber)
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language } = this.props

    return (
      <Form layout='horizontal' onFinish={this.handleSubmit} ref={this.formRef}>
        <Form.Item
          name='flag'
          rules={[{ required: true, message: messageTypes[language].input_answer_please }]}
        >
          <Input prefix={<FlagOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={messageTypes[language].answer} />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
          >
            {messageTypes[language].send}
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

export default QuizAnswerForm
