import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getChallenges } from '../../../actions'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import StyledChallenges from '../../../styledComponents/sections/challenges/'
import StyledLoader from '../../../styledComponents/common/loader'
import { messageTypes } from '../../../actions/messages'
import ChallengeModal from './modal'

class Challenges extends Component {
  constructor () {
    super()
    this.state = {
      challenges: [],
      challenge: null,
      modalVisible: false,
      userScore: undefined
    }
  }

  setChallenge = (challenge) => {
    this.setState({ challenge })
  }

  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible })
  }

  getChallenges = () => {
    getChallenges(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            challenges: json.response.challenges,
            userScore: json.response.userScore
          })

          if (this.state.challenge) {
            const challengeCategory = json.response.challenges.find(x => x._id === this.state.challenge.challenge_category._id)
            const challenge = challengeCategory && challengeCategory.items.find(item => String(item._id) === String(this.state.challenge._id))
            this.setState({ challenge })
          }
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
        }
      }
    })
  }

  componentDidMount () {
    this._isMounted = true

    this.getChallenges()
  }

  componentDidUpdate (prevProps, prevState) {
    const { ctfState } = this.props

    // when switching stack and are loaded machines, clear (hide) machines list
    if (this.props.stack === 'switching' && this.state.challenges.length) {
      this.setState({ challenges: [] })
    }

    // when stack switched, load machines again
    if ((prevProps.stack === 'switching' && typeof this.props.stack === 'object') || (ctfState !== prevProps.ctfState && ctfState === 'BATTLE')) {
      this.getChallenges()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { stack, language } = this.props
    const { challenges, userScore } = this.state

    return (
      <div>
        {userScore !== undefined && <div className='user-points'>{messageTypes[language].user_points}: {userScore}</div>}
        <ChallengeModal
          refreshData={this.getChallenges}
          element={this.state.challenge}
        />
        <StyledChallenges
          challenges={challenges}
          language={language}
          setModalVisible={this.setModalVisible}
          setChallenge={this.setChallenge}
        />
        <StyledLoader fadeOut={challenges.length && stack !== 'switching'} fadeIn={!challenges.length || stack === 'switching'} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  ctfState: state.hdStore.ctfState
})

const ChallengesContainer = connect(
  mapStateToProps
)(Challenges)

export default ChallengesContainer
